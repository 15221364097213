import {mapActions, mapGetters, mapMutations} from "vuex";
import mainSelect from '@/components/main-select/index.vue';
import searchSelect from '@/components/search-select/index.vue';
//sections

export default {
  name: "product",
  components: {
    mainSelect,
    searchSelect,
  },
  data() {
    return {
      selectCity: {},
      selectCountry: {},
      status: false,
      selectStatus: false,
      location: {
        lat: null,
        lon: null,
      }
    }
  },
  created() {
    this.getLocation()
    this.getCountries()
  },
  computed: {
    ...mapGetters({
      nearest: 'retailers/location',
      locationLoading: 'retailers/locationLoading',
      countries: 'retailers/countries',
      cities: 'retailers/cities',
      contacts: 'pages/contact',
    }),
  },

  methods: {
    ...mapMutations({}),
    ...mapActions({
      getLocate: `retailers/GET_LOCATION`,
      getCountries: `retailers/GET_COUNTRIES`,
      getCities: `retailers/GET_CITIES`,
      getShop: `retailers/GET_SHOP`,
    }),
    getLocation() {
      if (navigator.geolocation) {
        console.log('Geolocation is supported!');
        let startPos;
        let geoOptions = {
          timeout: 10 * 1000
        }

        let geoSuccess = (position) => {
          startPos = position;
          console.log(startPos);
          this.location.lat = startPos.coords.latitude;
          this.location.lon = startPos.coords.longitude;
          this.getLocate(this.location).then(() => {
            this.status = true
          }).catch(error => {
            if (error.status === 422) {
              this.$toasted.error(error.data.message);
              const errors = error.data.errors;
              for (const i in errors) {
                errors[i].forEach(e => {
                  this.$toasted.error(e);
                })
              }
            }
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
              const errors = error.data.errors;
              for (const i in errors) {
                errors[i].forEach(e => {
                  this.$toasted.error(e);
                })
              }
            }
          })
        };
        let geoError = function (error) {
          console.log('Error occurred. Error code: ' + error.code);
          // error.code can be:
          //   0: unknown error
          //   1: permission denied
          //   2: position unavailable (error response from location provider)
          //   3: timed out
        };

        navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);
      } else {
        console.log('Geolocation is not supported for this Browser/OS version yet.');
      }

    },
    getCity(item) {
      this.selectStatus = false
      this.selectCity = {}
      this.getCities(item).then(() => {
        this.selectStatus = true
      }).catch(error => {
        if (error.status === 422) {
          this.$toasted.error(error.data.message);
          const errors = error.data.errors;
          for (const i in errors) {
            errors[i].forEach(e => {
              this.$toasted.error(e);
            })
          }
        }
        if (error.status === 418) {
          this.$toasted.error(error.data.message);
          const errors = error.data.errors;
          for (const i in errors) {
            errors[i].forEach(e => {
              this.$toasted.error(e);
            })
          }
        }
      })
    },
    getShops(item) {
      if (Object.keys(item).length > 0) {
        this.getShop(item).then(() => {
          this.status = true
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
          if (error.status === 418) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for (const i in errors) {
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      } else {
        console.log('error shops');
      }
    }
  }
}